@import "Styles/overide-styles.css";
@import url('https://fonts.googleapis.com/css?family=Poppins');

/*// default theme(light)*/
html {
    --primaryBackground: #0E131A;
    --primaryForeGround: #191F28;
    --primaryTextColor: #FFFFFF;
    --primaryButtonColor: #49484D;


    --blueGradientDark: #4179F9;
    --blueGradientLight: #05B0DA;

}

/*// dark theme*/
html[data-theme="light"] {
    --primaryBackground: #1E1E20;
    --primaryForeGround: #fff;
    --primaryTextColor: #FFFFFF;
}

body {
    font-family: "Poppins", "Helvetica Neue", serif !important;
    background: var(--primaryBackground); /* Old browsers */
}

.ui{
    font-family: "Poppins", "Helvetica Neue", serif !important;

}

.ui.inverted.form.inline.fields {
    text-align: center;
    display: inline-block !important;
    align-items: center;
    justify-content: center
}

.ui.inverted.top.fixed.menu {
    background: var(--primaryForeGround);
}

.App {
    text-align: center;
    background-color: #191F28;
    color: white;
}

.page-content {
    background-color: #191F28;
    padding-top: 55px;
    min-height: calc(100vh - 30px);
}

.footer p{
    color: inherit !important;
}

.footer a{
    color: inherit !important;

}

.list-items-stats{
    list-style: none;
    padding-left: 0 !important;
}

.footer {
    height: 50px;
}


