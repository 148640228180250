.ui.inverted.menu {
    background: #191F28;
}

.ui[class*="top fixed"].menu{
    border-bottom: 1px solid black;
}

.ui.segment {
    background: #191F28;
}

.ui.inverted.table {
    background: #191F28;
}

.ui.message {
    margin: 0 !important;
}

.ui.button{
    border-radius:2px !important;
}

.ui.button:hover{
    filter: brightness(1.2);
}

.ui.button.retry {
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #EE3026 0%, #FB521E 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EE3026', endColorstr='#fb703c', GradientType=0); /* IE6-9 */
}

.ui.button.warn {
    background: linear-gradient(to top right, #ff8e1e 0, #ffab40) !important;
}

.ui.button.negative {
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #EE3026 0%, #FB521E 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EE3026', endColorstr='#fb703c', GradientType=0); /* IE6-9 */
}

/*.ui.negative.button.information {*/
/*    background: linear-gradient(to top right, #ff0900 0, #b20800) !important;*/
/*}*/

.ui.table td.negative, .ui.table tr.negative.heavy-error {
    background-color: #350909 !important;
    color: white !important;
}

.ui.table td.negative, .ui.table tr.negative.heavy-error:hover {
    background-color: #350909 !important;
    color: white;
}

.ui.negative.message.heavy-error {
    background-color: #350909 !important;
    color: white;

}

.ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
    margin: 0;
}

.centered-button {
    display: flex;
    justify-content: center;
}

.ui.table tr td.disabled, .ui.table tr.disabled td, .ui.table tr.disabled:hover, .ui.table tr:hover td.disabled {
    pointer-events: all !important;
}

.ui.table tr td.disabled, .ui.table tr.disabled td, .ui.table tr.disabled:hover, .ui.table tr:hover td.disabled:hover {
    background: none;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
    background: var(--primaryForeGround);
    color: rgba(1, 1, 1, 0.9);
    font-family: "Poppins", "Helvetica Neue", serif !important;
}

.ui.fluid.selection.dropdown {
    background: var(--primaryForeGround);
    color: white;
    border-color: #2B3453;
}

.ui.fluid.selection.dropdown.selected > div{
    color: white;
    border-color: #2B3453;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(left, #4179F9 0%, #05B0DA 100%) !important;; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%) !important;; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%) !important;; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
}

.ui.inverted.segment.dashboard-section {
    /*max-width: calc(100% - 2em);*/
    margin-bottom: 1.5em;
    margin-top: 0.5em;
    width: 100%;
    max-height: 80vh;
}

.ui.inverted.segment.overflow {
    overflow: auto;
}

.ui.button {
    background: var(--primaryButtonColor);
    color: var(--primaryTextColor);
    font-weight: 300;
}

.ui.primary.button, .ui.pagination.menu .active.item{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(left, #4179F9 0%, #05B0DA 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
    color: white;
    font-weight: 500 !important;
}

.ui.secondary.button {
    background: #3968BA; /* Old browsers */
    background: -moz-linear-gradient(left, #2b9439 0%, #4dde50 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #2b9439 0%, #4dde50 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #2b9439 0%, #4dde50 170%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#151A22', endColorstr='#191F28', GradientType=0); /* IE6-9 */

    color: white;
}

.ui.secondary.button.livingstone{
    background: #3968BA; /* Old browsers */
    background: -moz-linear-gradient(left, #92c01f 0%, #4dde50 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #92c01f 0%, #4dde50 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #92c01f 0%, #4dde50 170%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#92c01f', endColorstr='#191F28', GradientType=0); /* IE6-9 */

    color: white;
}

.ui.positive.button{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(left, #3968ba 0%, #05B0DA 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4179F9 0%, #05B0DA 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4179F9 0%, #05B0DA 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
}

.ui.negative.button {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #EE3026 0%, #FB521E 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #EE3026 0%, #FB521E 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EE3026', endColorstr='#fb703c', GradientType=0); /* IE6-9 */
}

.ui.negative.button.warn {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(to top right, #ff8e1e 0%, #FFAB40 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, #ff8e1e 0%, #FFAB40 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #ff8e1e 0%, #FFAB40 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFAB40', endColorstr='#FFAB40', GradientType=0); /* IE6-9 */
}

.ui.toggle.checkbox.label {
    color: white;
}

.ui.celled.inverted.selectable.table {
    background: var(--primaryForeGround)
}

.ui.table td.positive, .ui.table tr.positive {
    background: var(--primaryBackground) !important;
    /*background-color: #2b9439!important;*/
    color: #9a9a9a !important;
}

.ui.table td.negative, .ui.table tr.negative {
    color: #dbdbdb !important;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(left, #761e18 0%, #82200a 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #761e18 0%, #82200a 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, var(--primaryBackground) -30%, #350909 70%, #bb2e21 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#761e18', endColorstr='#82200a', GradientType=0) !important; /* IE6-9 */
}

.ui.pagination.menu {
    border-color: #8a8688;
    /*border-color: #8a8688;*/

}

.ui.pagination.menu .item {
    background: var(--primaryBackground);
    color: white;
}

.ui.action.input > input, .ui.action.input > div, .ui.active.visible.compact.selection.dropdown .text{
    background: var(--primaryBackground);
    color: white;
}

.gradient-red {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(45deg, #EE3026 0%, #fb703c 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #EE3026 0%, #fb703c 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #EE3026 0%, #fb703c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EE3026', endColorstr='#fb703c', GradientType=0); /* IE6-9 */
}

.light-blue-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544 !important; /* Old browsers */
    background: -moz-linear-gradient(45deg, #4179F9 0%, #05B0DA 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #4179F9 0%, #05B0DA 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, #4179F9 0%, #05B0DA 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4179F9', endColorstr='#1E243A', GradientType=0) !important; /* IE6-9 */
    border-radius: 4px;
}

.gradient-blue {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222544+0,fa364c+100 */
    background: #222544; /* Old browsers */
    background: -moz-linear-gradient(top, #394364 0%, #1E243A 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #394364 0%, #1E243A 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #394364 0%, #1E243A 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#394364', endColorstr='#1E243A', GradientType=0); /* IE6-9 */
}

.add-file-button{
    max-width:220px;
}

.label-color label {
    color:white !important;
}

.label-color-black label  {
    color:black !important;
}